const extensionHandle = 'neauviaOverlay';

export interface WistiaOverlayReadyEventDetails {
  node: HTMLElement;
}

class WistiaOverlay {
  static type = 'foreground';
  static handle = extensionHandle;
  static sortValue = 500;
  node: HTMLElement | null = null;

  constructor(public player: WistiaPlayer.Player) {}

  mount(parent: HTMLElement) {
    const node = document.createElement('div');
    this.node = node;
    parent.appendChild(node);

    node.dispatchEvent(
      new CustomEvent<WistiaOverlayReadyEventDetails>('wistiaOverlayReady', {
        bubbles: true,
        detail: {
          node,
        },
      }),
    );
  }

  destroy() {
    this.node?.remove();
  }
}

export const attachWistiaOverlay = () => {
  if (Wistia._controlDefinitions[extensionHandle] != null) {
    return;
  }

  Wistia.defineControl(WistiaOverlay);
};
