import { memo } from 'react';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { Videos } from 'src/graphql/schema.graphql';
import styled from 'styled-components';

const Thumbnail = styled(DirectusImage)`
  object-fit: cover;
  object-position: center;
`;

export const PlayerThumbnail = memo<{ video: Videos }>(
  function PlayerThumbnail({ video }) {
    if (video.thumbnail == null) {
      return null;
    }

    return <Thumbnail src={video.thumbnail.id} layout="fill" sizes="90vw" />;
  },
);
