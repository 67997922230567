import { localStorage } from 'src/utils/storage';

export function unmuteWistia(wistiaPlayer: WistiaPlayer.Player): Promise<void> {
  function tryToUnmuteThisCrap() {
    wistiaPlayer.unmute();
    const volume = wistiaPlayer.volume();
    if (volume === 0) {
      const userPreferences = localStorage.getItem<Record<string, any>>(
        'wistia-viewer-preferences',
      );
      wistiaPlayer.volume(userPreferences?.volume ?? 1);
    }
  }

  return new Promise<void>((resolve) => {
    tryToUnmuteThisCrap();
    setTimeout(() => {
      tryToUnmuteThisCrap();
      resolve();
    }, 10);
  });
}
