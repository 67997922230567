import React, { createRef, PureComponent } from 'react';
import {
  PlayerStrategyAPI,
  PlayerStrategyProps,
} from 'src/components/player/playerStrategy/types';
import { PlayerContainer } from 'src/components/player/common';
import { v4 } from 'uuid';
import {
  attachWistiaOverlay,
  WistiaOverlayReadyEventDetails,
} from 'src/components/player/playerStrategy/WistiaPlayerStrategy/WistiaOverlay';
import ReactDOM from 'react-dom';
import { wistiaConfigProviderFactory } from 'src/components/player/playerStrategy/WistiaPlayerStrategy/wistiaConfigProviderFactory';
import { last } from 'rambda';
import styled, { css } from 'styled-components';
import { unmuteWistia } from 'src/components/player/playerStrategy/WistiaPlayerStrategy/unmuteWistia';
import { casePath } from '@summer/jst-functions';
import { PlayerThumbnail } from 'src/components/player/PlayerThumbnail';

const WistiaContainer = styled(PlayerContainer)<{
  $fullscreen: boolean;
  $blockPause: boolean;
  $customThumbnail: boolean;
}>`
  ${casePath('$fullscreen')(
    () => '',
    () => css`
      overflow: hidden;

      & > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
      }

      .w-chrome,
      .w-chrome [id$='main'],
      .w-chrome [id$='wrapper'] {
        width: 100% !important;
        height: 100% !important;
        overflow: visible !important;
      }
    `,
  )};

  .w-video-wrapper {
    background: none !important;
  }

  .w-big-play-button {
    & > div {
      display: none !important;
    }
    & > svg {
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 1)) !important;
    }
  }

  .w-context-menu-anchor {
    display: none !important;
  }

  .w-bottom-bar {
    z-index: 10;
  }

  [data-handle='clickForSoundButton'] {
    display: none !important;
  }

  ${casePath('$customThumbnail')(
    () => css`
      background: transparent;

      [data-handle='thumbnail'] {
        display: none !important;
      }
    `,
    () => '',
  )};

  /* 
    Following operations on pointer-events are required to block pausing TV player. 
    We found no other way to disable pausing it. We need to re-enable pointer-events for controls. 
  */
  ${casePath('$blockPause')(
    () => css`
      .w-chrome {
        pointer-events: none;
      }

      .w-vulcan-overlays-table > * {
        pointer-events: all;

        &:hover,
        &:focus {
          .w-bottom-bar-right {
            opacity: 1 !important;
          }
        }
      }

      div[data-handle='playPauseLoading'] {
        display: none !important;
      }
    `,
    () => '',
  )};
`;

interface ComponentState {
  mounted?: boolean;
  overlayNode?: HTMLElement;
}

export default class WistiaPlayerStrategy
  extends PureComponent<PlayerStrategyProps, ComponentState>
  implements PlayerStrategyAPI
{
  state: ComponentState = {};

  constructor(props: PlayerStrategyProps) {
    super(props);
    props.innerRef?.(this);
  }

  getCurrentTime(): number {
    return this.player?.time() ?? 0;
  }

  getRealTime(): number {
    return this.getCurrentTime();
  }

  async enterFullscreen(): Promise<void> {
    this.player?.requestFullscreen();
  }

  async exitFullscreen(): Promise<void> {
    this.player?.cancelFullscreen();
  }

  componentDidMount() {
    this.setState({ mounted: true });

    this.bindContainer();

    this.initConfig = {
      id: this.id,
      onReady: this.onReady,
      onHasData: attachWistiaOverlay,
    };
    window._wq = window._wq || [];
    window._wq.push(this.initConfig);
  }

  componentDidUpdate(prevProps: PlayerStrategyProps) {
    this.unbindContainer();
    this.bindContainer();

    if (prevProps.muted !== this.props.muted && this.player != null) {
      if (this.props.muted && !this.player.isMuted()) {
        this.player.mute();
      } else if (!this.props.muted && this.player.isMuted()) {
        unmuteWistia(this.player);
      }
    }

    if (prevProps.volume !== this.props.volume && this.props.volume != null) {
      if (this.player?.volume() !== this.props.volume) {
        this.player?.volume(this.props.volume);
      }
    }

    if (
      prevProps.playFrom !== this.props.playFrom &&
      this.props.playFrom != null
    ) {
      if (this.player?.time() !== this.props.playFrom) {
        this.player?.time(this.props.playFrom);
      }
    }

    if (prevProps.playing !== this.props.playing) {
      if (this.props.playing && this.player?.state() !== 'playing') {
        this.player?.play();
      } else if (!this.props.playing && this.player?.state() !== 'paused') {
        this.player?.pause();
      }
    }

    if (prevProps.video.source?.url !== this.props.video.source?.url) {
      this.player?.replaceWith(this.getWistiaId(), this.getWistiaOptions()[0]);
    }

    if (prevProps.fullscreen !== this.props.fullscreen && this.player != null) {
      if (!this.props.fullscreen && this.player.inFullscreen()) {
        this.exitFullscreen();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.exitFullscreen();
    this.unbindPlayer();
    this.unbindContainer();
    this.player?.remove();
    window._wq = window._wq || [];
    window._wq.push({ revoke: this.initConfig });
  }

  render() {
    const { children, video, playedOnce, fullscreen, wistiaOptions, controls } =
      this.props;
    const { overlayNode, mounted } = this.state;

    const videoId = this.getWistiaId();
    const className = `wistia_embed wistia_async_${videoId} ${
      this.getWistiaOptions()[1]
    }`;

    return mounted ? (
      <>
        {!playedOnce && <PlayerThumbnail video={video} />}

        <WistiaContainer
          data-keep-focus
          ref={this.containerRef}
          id={this.id}
          className={className}
          $fullscreen={fullscreen === true}
          $blockPause={
            controls === false ||
            (wistiaOptions?.playbar === false &&
              wistiaOptions?.playButton === false &&
              wistiaOptions?.smallPlayButton === false)
          }
          $customThumbnail={!playedOnce}
        />

        {fullscreen && overlayNode != null
          ? ReactDOM.createPortal(children, overlayNode)
          : children}
      </>
    ) : (
      <></>
    );
  }

  private id = v4();
  private initConfig?: unknown;
  private wistiaConfigProvider = wistiaConfigProviderFactory();
  private containerRef = createRef<HTMLDivElement>();
  private player?: WistiaPlayer.Player;
  private _isMounted = true;

  private getWistiaId() {
    return last(this.props.video.source?.url.split(/\//) ?? []);
  }

  private getWistiaOptions(): [WistiaPlayer.PlayerOptions, string] {
    return this.wistiaConfigProvider(
      this.props.controls ?? true,
      this.props.volume,
      this.props.loop,
      this.props.playing,
      this.props.muted,
      this.props.playFrom,
      this.props.wistiaOptions,
    );
  }

  private bindPlayer() {
    this.player?.bind('play', this.onPlay);
    this.player?.bind('pause', this.onPause);
    this.player?.bind('seek', this.onSeek);
    this.player?.bind('volumechange', this.onVolumeChange);
    this.player?.bind('mutechange', this.onMuteChange);
    this.player?.bind('end', this.onEnded);
    this.player?.bind('enterfullscreen', this.onEnterFullscreen);
    this.player?.bind('cancelfullscreen', this.onExitFullscreen);
  }

  private unbindPlayer() {
    this.player?.unbind('play', this.onPlay);
    this.player?.unbind('pause', this.onPause);
    this.player?.unbind('seek', this.onSeek);
    this.player?.unbind('volumechange', this.onVolumeChange);
    this.player?.unbind('mutechange', this.onMuteChange);
    this.player?.unbind('end', this.onEnded);
    this.player?.unbind('enterfullscreen', this.onEnterFullscreen);
    this.player?.unbind('cancelfullscreen', this.onExitFullscreen);
  }

  private bindContainer() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.containerRef.current?.addEventListener(
      'wistiaOverlayReady',
      this.onOverlayReady,
    );
  }

  private unbindContainer() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.containerRef.current?.removeEventListener(
      'wistiaOverlayReady',
      this.onOverlayReady,
    );
  }

  // -- HANDLERS START

  private onReady = (player: WistiaPlayer.Player) => {
    if (!this._isMounted) {
      player.remove();
      return;
    }

    this.player = player;
    this.unbindPlayer();
    this.bindPlayer();

    if (this.props.volume != null && player.volume() !== this.props.volume) {
      player.volume(this.props.volume);
    }

    if (this.props.muted === false && this.player.isMuted()) {
      unmuteWistia(player);
    }

    if (this.props.muted === true && !this.player.isMuted()) {
      player.mute();
    }

    if (
      this.props.playFrom != null &&
      this.player?.time() !== this.props.playFrom
    ) {
      player.time(this.props.playFrom);
    }

    if (this.props.playing && this.player?.state() !== 'playing') {
      this.player?.play();
    } else if (!this.props.playing && this.player?.state() !== 'paused') {
      this.player?.pause();
    }

    this.props.onReady?.();
  };

  private onPlay = () => this.props.onPlay?.();

  private onPause = () => this.props.onPause?.();

  private onSeek = (currentTime: number) => this.props.onSeek?.(currentTime);

  private onVolumeChange = (volume: number) =>
    this.props.onVolumeChange?.(volume);

  private onMuteChange = (isMuted: boolean) =>
    isMuted ? this.props.onMute?.() : this.props.onUnmute?.();

  private onEnded = () => this.props.onEnded?.();

  private onEnterFullscreen = () => this.props.onEnterFullscreen?.();

  private onExitFullscreen = () => this.props.onLeaveFullscreen?.();

  private onOverlayReady = ({
    detail: { node },
  }: CustomEvent<WistiaOverlayReadyEventDetails>) =>
    this.setState({ overlayNode: node });

  // -- HANDLERS END
}
