import memoizee from 'memoizee';
import { stringify } from 'query-string';

export const wistiaConfigProviderFactory = () =>
  memoizee(
    (
      controls: boolean,
      volume: number | undefined,
      loop: boolean | undefined,
      playing: boolean | undefined,
      muted: boolean | undefined,
      playFrom: number | undefined,
      opts?: Partial<WistiaPlayer.PlayerOptions>,
    ): [WistiaPlayer.PlayerOptions, string] => {
      const options: WistiaPlayer.PlayerOptions = {
        playsinline: true,
        seo: false,
        preload: 'none',
        playPauseNotifier: true,
        playbackRateControl: false,
        fakeFullscreen: true,
        copyLinkAndThumbnailEnabled: false,
        controlsVisibleOnLoad: false,
        fullscreenOnRotateToLandscape: true,
        playSuspendedOffScreen: true,
        playButton: true,
        googleAnalytics: false,
        resumable: false,
        transition: 'fade',

        fullscreenButton: controls,
        playbar: controls,
        qualityControl: controls,
        volumeControl: controls,
        settingsControl: controls,
        smallPlayButton: controls,

        endVideoBehavior: loop ? 'loop' : 'default',
        silentAutoPlay: 'allow',
        volume,
        autoPlay: playing,
        muted,
        time: !loop && (playFrom ?? 0) > 0 ? `${playFrom}` : undefined,
        ...opts,
      };

      return [options, stringify(options).replace(/&/g, ' ')];
    },
  );
